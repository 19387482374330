import "./styles.css";
import logo from "./assets/BA_Type 4.png";
import mark from "./assets/BA_Mark 1.png";

import "./assets/fonts/MaisonNeue-Mono.ttf";

export default function App() {
  const text =
    "• PREMIUM CANNABIS GROWN IN RANGIORA AOTEAROA • SITE COMING SOON ";
  let premium = text.repeat(10);

  return (
    <section>
      <img className="logo" alt="" src={logo} />

      <div class="description horizontal">
        <div class="trackH">
          <div class="content">{premium}</div>
        </div>
      </div>

      <div class="description vertical">
        <div class="trackV">
          <div class="content">{premium}</div>
        </div>
      </div>

      <img className="mark" alt="" src={mark} />
    </section>
  );
}
